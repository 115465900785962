import React from 'react'
import { Link, graphql } from 'gatsby'
import { css } from '@emotion/core'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Newsletter from '../components/newsletter'
import { rhythm } from '../utils/typography'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.blogPosts.edges

    return (
      <Layout location={this.props.location} title={siteTitle} frontmatter={{ hero: true }}>
        <SEO title="Home Page" url="/" />
        <div>
          <h3
            css={css`
              margin-top: 0;
            `}
          >
            Newest posts
          </h3>

          <div>
            {posts.map(({ node: post }) => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <article key={post.fields.slug}>
                  <header>
                    <h3>
                      <Link css={css`box-shadow: none`} to={post.fields.slug}>
                        {title}
                      </Link>
                    </h3>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                    />
                  </section>
                </article>
              )
            })}

            <Link
              css={css`
                box-shadow: none;
                display: inline-block;
                font-weight: bold;
                margin-bottom: ${rhythm(1)};
              `}
              to="/blog"
            >
              👉 All Posts
            </Link>
          </div>
          <hr />
          <Newsletter/>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    blogPosts: allMdx(
      filter: {
        frontmatter: {published: {ne: false}}
        fileAbsolutePath: {regex: "//src/content/blog//"}
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
            description
          }
        }
      }
    }
  }
`
